import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import { ForgetPasswordComponent } from './forget-password.component';

const FORGET_PASSWORD: Routes = [
    { path: '', component : ForgetPasswordComponent}
];
@NgModule({
    imports: [RouterModule.forChild(FORGET_PASSWORD)],
    exports: [RouterModule]
})
export class ForgetPasswordRoutingModule {
}

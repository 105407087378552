<!-- Page header -->
<section class="page-header bg-header-next">
  <div class="container">
    <div class="row">
      <div class="col-md-12 page-title text-center">
        <h2>Alerts</h2>
      </div>
    </div>
  </div>
</section>
<!-- End of Page header -->

<!-- Main contents -->
<section class="content content-section">
  <div class="container">
    <div class="row">
      <div class="col-md-8">
        <div class="inner-tabs nav-tabs-wrapper">
          <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item" (click)="changeActiveTab('latest-tab')">
              <a class="nav-link" [ngClass]="activetab == 'latest-tab' ? 'active': ''" id="latest-tab" data-toggle="tab" href="#latest-alerts" role="tab"
                aria-controls="for-you" aria-selected="true">Latest Alerts</a>
            </li>
            <li class="nav-item" (click)="changeActiveTab('earthquake-tab')">
              <a class="nav-link" [ngClass]="activetab == 'earthquake-tab' ? 'active': ''" id="earthquake-tab" data-toggle="tab" href="#earthquake-info" role="tab"
                aria-controls="news" aria-selected="false">Earthquake Info</a>
            </li>
            <li class="nav-item" (click)="changeActiveTab('volcano-tab')">
              <a class="nav-link" [ngClass]="activetab == 'volcano-tab' ? 'active': ''" id="volcano-tab" data-toggle="tab" href="#volcano-alerts" role="tab"
                aria-controls="alerts" aria-selected="false">Volcano Alerts</a>
            </li>
            <li class="nav-item" [ngClass]="activetab == 'weather-tab' ? 'active': ''" (click)="changeActiveTab('weather-tab')">
              <a class="nav-link" id="weather-tab" data-toggle="tab" href="#weather-info" role="tab"
                aria-controls="preference" aria-selected="false">Weather Info</a>
            </li>
          </ul>
          <div class="tab-content" id="myTabContent">
            <div class="tab-pane fade" [ngClass]="activetab == 'latest-tab' ? 'show active': ''" id="latest-alerts" role="tabpanel">
              <div class="row">
                <div class="col-md-12">
                    <ng-container *ngIf="latestAlertList != undefined && latestAlertList.length > 0; else loader">
                        <ng-container *ngFor="let alert of latestAlertList;  trackBy:trackAlertByCode;">
                            <ng-container *ngIf="alert == -1; else hasLatestAlertData">
                              <h6 class="col-lg-12 text-center">
                                Data Unavailable.
                              </h6>
                            </ng-container>
                            <ng-template #hasLatestAlertData>
                              <ng-container *ngIf="alert.label === 'Earthquake'">
                                <div class="alerts-item d-flex justify-content-between">
                                    <div class="l-detail d-flex">
                                      <img src="assets/img/icon-earthquake.svg">
                                      <div class="alert-wrap">
                                        <a [routerLink]="['/earthquake/en/' + alert.code]">{{ alert.epicenter_value }}</a>
                                        <div class="mt-1">
                                          <item>
                                            <span>{{ alert.occurred_value | date : 'yyyy-MM-dd' }}</span>
                                            <span>
                                              {{ alert.occurred_value | date : 'HH:mm' }}</span>
                                          </item>
                                          <span>{{alert.magnitude_title}} {{alert.magnitude_value}}</span>
                                        </div>
            
                                      </div>
                                    </div>
                                    <div class="r-info text-center">
                                      <strong class="d-block mb-2">{{alert.magnitude_value}}</strong>
                                      <span>{{alert.occurred_value | dateAgo}}</span>
                                    </div>
                                  </div>
                              </ng-container>
                              <ng-container *ngIf="alert.label === 'Volcano'">
                                  <div class="alerts-item d-flex justify-content-between">
                                      <div class="l-detail d-flex">
                                        <img src="assets/img/icon-volcano.svg">
                                        <div class="alert-wrap">
                                          <a [routerLink]="['/volcano/en/' + alert.volcano_code]">{{alert.volcano_name_value}}</a>
                                          <div class="mt-1">
                                            <item>
                                              <span>{{ alert.occurred_value | date : 'yyyy-MM-dd' }}</span>
                                              <span> {{ alert.occurred_value | date : 'HH:mm' }}</span>
                                            </item>
                                            <span>{{alert.level_title}} {{alert.level_value}} {{alert.level_text ? (alert.level_text) : ''}}</span>
                                          </div>
                                        </div>
                                      </div>
                                  </div>
                              </ng-container>
                            </ng-template>
                        </ng-container>
                        <ng-container *ngIf="showLatestAlertLoadMore">
                          <div class="row justify-content-center mt-5">
                            <button type="submit" class="btn btn-orange s-btn" (click)="fetchLatestAlerts()">
                                <i class="fa fa-spinner fa-spin mr-2" *ngIf="fetchingLatestAlerts"></i>{{fetchingLatestAlerts ? 'Loading' : 'Load More'}}
                            </button>
                          </div>
                        </ng-container>
                    </ng-container>
                </div>
              </div>
            </div>
            <div class="tab-pane fade" [ngClass]="activetab == 'earthquake-tab' ? 'show active': ''" id="earthquake-info" role="tabpanel">
              <div class="row">
                <div class="col-md-12">
                  <ng-container *ngIf="earthquakeList != undefined && earthquakeList.length > 0; else loader">
                    <ng-container *ngFor="let earthquake of earthquakeList;  trackBy:trackAlertByCode;">
                      <ng-container *ngIf="earthquake == -1; else hasEarthquakeData">
                        <h6 class="col-lg-12 text-center">
                          Data Unavailable.
                        </h6>
                      </ng-container>
                      <ng-template #hasEarthquakeData>
                        <div class="alerts-item d-flex justify-content-between">
                          <div class="l-detail d-flex">
                            <img src="assets/img/icon-earthquake.svg">
                            <div class="alert-wrap">
                              <a [routerLink]="['/earthquake/en/' + earthquake.code]">{{ earthquake.epicenter_value }}</a>
                              <div class="mt-1">
                                <item>
                                  <span>{{ earthquake.occurred_value | date : 'yyyy-MM-dd' }}</span>
                                  <span>
                                    {{ earthquake.occurred_value | date : 'HH:mm' }}</span>
                                </item>
                                <span>{{earthquake.magnitude_title}} {{earthquake.magnitude_value}}</span>
                              </div>
  
                            </div>
                          </div>
                          <div class="r-info text-center">
                            <strong class="d-block mb-2">{{earthquake.magnitude_value}}</strong>
                            <span>{{earthquake.occurred_value | dateAgo}}</span>
                          </div>
                        </div>
                      </ng-template>
                    </ng-container>
                    <ng-container *ngIf="showEarthquakeLoadMore">
                      <div class="row justify-content-center mt-5">
                        <button type="submit" class="btn btn-orange s-btn" (click)="fetchLatestEarthquakes()">
                            <i class="fa fa-spinner fa-spin mr-2" *ngIf="fetchingEarthquakes"></i>{{fetchingEarthquakes ? 'Loading' : 'Load More'}}
                        </button>
                      </div>
                    </ng-container>
                  </ng-container>
                </div>
              </div>
            </div>
            <div class="tab-pane fade" [ngClass]="activetab == 'volcano-tab' ? 'show active': ''" id="volcano-alerts" role="tabpanel">
              <div class="row">
                <div class="col-md-12">
                    <ng-container *ngIf="volcanoList != undefined && volcanoList.length > 0; else loader">
                        <ng-container *ngFor="let volcano of volcanoList;  trackBy:trackAlertByCode;">
                          <ng-container *ngIf="volcano == -1; else hasVolcanoData">
                            <h6 class="col-lg-12 text-center">
                              Data Unavailable.
                            </h6>
                          </ng-container>
                          <ng-template #hasVolcanoData>
                            <div class="alerts-item d-flex justify-content-between">
                              <div class="l-detail d-flex">
                                <img src="assets/img/icon-volcano.svg">
                                <div class="alert-wrap">
                                  <a [routerLink]="['/volcano/en/' + volcano.volcano_code]">{{volcano.volcano_name_value}}</a>
                                  <div class="mt-1">
                                    <item>
                                      <span>{{ volcano.occurred_value | date : 'yyyy-MM-dd' }}</span>
                                      <span> {{ volcano.occurred_value | date : 'HH:mm' }}</span>
                                    </item>
                                    <span>{{volcano.level_title}} {{volcano.level_value}} {{volcano.level_text ? (volcano.level_text) : ''}}</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </ng-template>
                        </ng-container>
                        <ng-container *ngIf="showVolcanoLoadMore">
                          <div class="row justify-content-center mt-5">
                            <button type="submit" class="btn btn-orange s-btn" (click)="fetchLatestVolcanos()">
                                <i class="fa fa-spinner fa-spin mr-2" *ngIf="fetchingVolcanos"></i>{{fetchingVolcanos ? 'Loading' : 'Load More'}}
                            </button>
                          </div>
                        </ng-container>
                    </ng-container>
                </div>
              </div>
            </div>
            <div class="tab-pane fade" [ngClass]="activetab == 'weather-tab' ? 'show active': ''" id="weather-info" role="tabpanel">
              <div class="row">
                <div class="col-md-12">
                  <ng-container *ngIf="!fetchingWarningList && latestWarningList != undefined; else loader">
                    <ng-container *ngIf="latestWarningList.length > 0; else noWarningData">
                      <ng-container *ngFor="let warning of latestWarningList">
                        <ng-container *ngIf="warning == -1; else hasWarningData">
                          <h6 class="col-lg-12 text-center">
                            Data Unavailable.
                          </h6>
                        </ng-container>
                        <ng-template #hasWarningData>
                          <div class="alerts-item d-flex justify-content-between">
                            <div class="l-detail d-flex">
                              <img src="assets/img/icon-weather.svg">
                              <div class="alert-wrap">
                                <a href="#">{{warning.warning_name_en}}</a>
                                <div class="mt-1">
                                  <item>
                                    <span>{{warning.municipality_name_en}}</span>
                                  </item>
                                  <span>Type {{warning.type}}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </ng-template>
                      </ng-container>
                    </ng-container>
                    <ng-template #noWarningData>
                      <div class="row align-items-center justify-content-center">
                        <p>Data unavailable.</p>
                      </div>
                    </ng-template>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4 right-card">
        <div class="recent-post">
          <h4 class="d-block mb-4">Recent Post</h4>
          <ng-container *ngIf="latestNewsList && latestNewsList.length > 0; else noRecentPosts">
            <ng-container *ngFor="let news of latestNewsList;  trackBy:trackNewsByLink;">
                <ng-container *ngIf="news == -1; else hasRecentPost">
                  <h6 class="col-lg-12">
                    Sorry, but nothing matched your news preferences. Please try again with some different news preferences.
                  </h6>
                </ng-container>
                <ng-template #hasRecentPost>
                  <div class="post-list">
                    <img  [imagepreloader]="news.image" [targetSection]="'news'" class="img-fluid" (click)="openNewsUrl(news.link)">
                    <div class="post-info ml-3">
                      <a class="link" (click)="openNewsUrl(news.link)">{{news.title}}</a>
                      <span>{{news.published_date | date:'d MMM, y'}}</span>
                    </div>
                  </div>
                </ng-template>
            </ng-container>
          </ng-container>
          <ng-template #noRecentPosts>
            <!-- Main contents -->
            <section class="content content-section loading-skeleton avoid-skeleton-padding">
              <div class="container">
                <div class="row">
                  <div class="col-md-12 right-card">
                    <div class="recent-post">
                      <div class="post-list">
                        <div class="s-default-img" style="height: 90px; width: 100px"></div>
                        <div class="post-info ml-3">
                          <p>Lorem ipsum dolor sit amet idjed sde</p>
                          <span>4 February, 2021</span>
                        </div>
                      </div>
                      <div class="post-list">
                        <div class="s-default-img" style="height: 90px; width: 100px"></div>
                        <div class="post-info ml-3">
                          <p>Lorem ipsum dolor sit ame sdsed</p>
                          <span>4 February, 2021</span>
                        </div>
                      </div>
                      <div class="post-list">
                        <div class="s-default-img" style="height: 90px; width: 100px"></div>
                        <div class="post-info ml-3">
                          <p>Lorem ipsum dolor sdfasdf de sdfes</p>
                          <span>4 February, 2021</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <!-- End of Main contents -->
          </ng-template>
          <ng-template #loader>
            <section class="content content-section loading-skeleton avoid-skeleton-padding">
              <div class="container">
                <div class="row">
                  <div class="col-md-12">
                    <div class="servicesList inner-tabs inner-service nav-tabs-wrapper">
                      <div class="head-tabs">
                        <div class="row">
                          <div class="col-md-12">
                            <div class="tab-content" id="myTabContent">
                              <div class="tab-pane fade show active" id="earthquake-info" role="tabpanel">
                                <div class="row">	
                                  <div class="col-md-12">
                                    <div class="alerts-item d-flex justify-content-between">
                                      <div class="l-detail d-flex">
                                        <div class="s-default-img s-round mr-3" style="height: 70px; width: 120px"></div>
                                        <div class="alert-wrap">
                                          <a >Northern Wakayama Pref</a>
                                          <div class="mt-1">
                                            <item>
                                              <span>2021/03/24</span>
                                              <span> 06:46</span>
                                            </item>
                                            <span>Maximum seismic intensity 4.2</span>
                                          </div>

                                        </div>
                                      </div>
                                      <div class="r-info text-center">
                                        <strong class="d-block mb-2">4.2</strong>
                                        <span>10h ago</span>
                                      </div>
                                    </div>
                                    <div class="alerts-item d-flex justify-content-between">
                                      <div class="l-detail d-flex">
                                        <div class="s-default-img s-round mr-3" style="height: 70px; width: 120px"></div>
                                        <div class="alert-wrap">
                                          <a >Northern Wakayama Pref</a>
                                          <div class="mt-1">
                                            <item>
                                              <span>2021/03/24</span>
                                              <span> 06:46</span>
                                            </item>
                                            <span>Maximum seismic intensity 4.2</span>
                                          </div>

                                        </div>
                                      </div>
                                      <div class="r-info text-center">
                                        <strong class="d-block mb-2">4.2</strong>
                                        <span>10h ago</span>
                                      </div>
                                    </div>
                                    <div class="alerts-item d-flex justify-content-between">
                                      <div class="l-detail d-flex">
                                        <div class="s-default-img s-round mr-3" style="height: 70px; width: 120px"></div>
                                        <div class="alert-wrap">
                                          <a >Northern Wakayama Pref</a>
                                          <div class="mt-1">
                                            <item>
                                              <span>2021/03/24</span>
                                              <span> 06:46</span>
                                            </item>
                                            <span>Maximum seismic intensity 4.2</span>
                                          </div>

                                        </div>
                                      </div>
                                      <div class="r-info text-center">
                                        <strong class="d-block mb-2">4.2</strong>
                                        <span>10h ago</span>
                                      </div>
                                    </div>
                                    <div class="alerts-item d-flex justify-content-between">
                                      <div class="l-detail d-flex">
                                        <div class="s-default-img s-round mr-3" style="height: 70px; width: 120px"></div>
                                        <div class="alert-wrap">
                                          <a >Northern Wakayama Pref</a>
                                          <div class="mt-1">
                                            <item>
                                              <span>2021/03/24</span>
                                              <span> 06:46</span>
                                            </item>
                                            <span>Maximum seismic intensity 4.2</span>
                                          </div>

                                        </div>
                                      </div>
                                      <div class="r-info text-center">
                                        <strong class="d-block mb-2">4.2</strong>
                                        <span>10h ago</span>
                                      </div>
                                    </div>
                                    <div class="alerts-item d-flex justify-content-between">
                                      <div class="l-detail d-flex">
                                        <div class="s-default-img s-round mr-3" style="height: 70px; width: 120px"></div>
                                        <div class="alert-wrap">
                                          <a >Northern Wakayama Pref</a>
                                          <div class="mt-1">
                                            <item>
                                              <span>2021/03/24</span>
                                              <span> 06:46</span>
                                            </item>
                                            <span>Maximum seismic intensity 4.2</span>
                                          </div>

                                        </div>
                                      </div>
                                      <div class="r-info text-center">
                                        <strong class="d-block mb-2">4.2</strong>
                                        <span>10h ago</span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>							
                      </div>												
                    </div>
                  </div>
                  
                </div>
              </div>
            </section>
            <!-- End of Main contents -->
          </ng-template>
        </div>
      </div>
    </div>
  </div>
  
</section>
<!-- End of Main contents -->
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class NewsService {

  private baseUrl = environment.apiUrl;

  private newsHeader = new HttpHeaders({
    'cache': 'true',
    'cache_group': 'news'
  });

  private authPreferenceHeader = new HttpHeaders({
    auth: 'true',
    'cache': 'true',
    'cache_group': 'news_preferences'
  });

  constructor(private http: HttpClient) { }

  getNews(page: number, genrePreference: string[], sourcePreference: string[], languagePreference: string[]) {
    let newsUrl = this.baseUrl + '/news?page=' + page + '&limit=12';
    if(genrePreference.length > 0) {
      genrePreference.forEach(genre => {
        newsUrl = newsUrl + '&genres=' + genre; // encodeURI(genre);
      });
    }
    if(sourcePreference.length > 0) {
      sourcePreference.forEach(source => {
        newsUrl = newsUrl + '&sources=' + source;
      });
    }
    if(languagePreference.length > 0) {
      languagePreference.forEach(language => {
        newsUrl = newsUrl + '&lang=' + language;
      });
    }
    return this.http.request('GET', newsUrl, {headers: this.newsHeader});
  }

  fetchLatestNwws(page: number, genrePreference: string[], sourcePreference: string[], languagePreference: string[]) {
    let newsUrl = this.baseUrl + '/news/latest?page=' + page + '&limit=12';
    if(genrePreference.length > 0) {
      genrePreference.forEach(genre => {
        newsUrl = newsUrl + '&genres=' + genre;
      });
    }
    if(sourcePreference.length > 0) {
      sourcePreference.forEach(source => {
        newsUrl = newsUrl + '&sources=' + source;
      });
    }
    if(languagePreference.length > 0) {
      languagePreference.forEach(language => {
        newsUrl = newsUrl + '&lang=' + language;
      });
    }
    return this.http.get(newsUrl, {headers: this.newsHeader})
  }

  getForYouNews(page: number, genrePreference: string[], sourcePreference: string[], languagePreference: string[]) {
    let newsUrl = this.baseUrl + '/news/foryou?page=' + page + '&limit=12';
    if(genrePreference.length > 0) {
      genrePreference.forEach(genre => {
        // let splittedGenre = genre.split(' ');
        // if(length > 0) {
        //   newsUrl = newsUrl + '&genres=' + encodeURI(splittedGenre.join('+'));
        // } else {
        //   newsUrl = newsUrl + '&genres=' + encodeURI(genre);
        // }
        newsUrl = newsUrl + '&genres=' + encodeURI(genre);
      });
    }
    if(sourcePreference.length > 0) {
      sourcePreference.forEach(source => {
        newsUrl = newsUrl + '&sources=' + source;
      });
    }
    if(languagePreference.length > 0) {
      languagePreference.forEach(language => {
        newsUrl = newsUrl + '&lang=' + language;
      });
    }
    return this.http.get(newsUrl, {headers: this.newsHeader});
  }

  getGenreList() {
    return this.http.get(this.baseUrl + '/news/genres', {headers: this.newsHeader});
  }

  getPreferencesList() {
    return this.http.get(this.baseUrl + '/news/preferences', {headers: this.newsHeader});
  }

  getUserNewsPreference() {
    return this.http.get(this.baseUrl + '/news/get_user_preferences', {headers: this.authPreferenceHeader});
  }

  // async asyncAwaitTest() {
  //   return await this.http.get(this.baseUrl + '/news/get_user_preferences', {headers: this.authPreferenceHeader})
  //     .pipe(delay(5000))
  //     .toPromise();
  // }

  saveUserNewsPreference(newsPreference: any) {
    return this.http.post(this.baseUrl + '/news/save_preferences', newsPreference, {headers: this.authPreferenceHeader});
  }
}

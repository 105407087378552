import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import { ServicesRoutingModule } from './services-routing.module';
import { ServicesComponent } from './services.component';
import { ServiceDetailsComponent } from './service-details/service-details.component';
import { ImagepreloadermoduleModule } from '../utils/directives/imagepreloader.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FilterPartnerServicesModule } from '../utils/pipes/filter-partner-services.module';

@NgModule({
    imports: [
        CommonModule,
        ServicesRoutingModule,
        ImagepreloadermoduleModule,
        FormsModule,
        ReactiveFormsModule,
        FilterPartnerServicesModule
    ],
    declarations: [
        ServicesComponent,
        ServiceDetailsComponent
    ],
    providers: [
        
    ]
})

export class ServicesModule {
}

<!-- Main conatiner -->
<section class="login-section">
    <div class="container">
        <div class="row">
            <div class="col-md-8 col-lg-4 mx-auto">			
                <div class="form-inner verify">
                    <img src="assets/img/icon-email-verify.svg" class="img-fluid mb-4">
                    <h2>Provide your email address</h2>
                    <!-- form -->
                    <form class="form-pannel" [formGroup]="passwordResetRequestForm" (ngSubmit)="requestPasswordResetCode()">
                        <div class="form-row">
                            <div class="col-md-12">
                                <p>Please enter your email address.</p>
                            </div>
                            <div class="form-group col-md-12" [ngClass]="passwordResetRequestForm.controls.email.errors && (passwordResetRequestForm.controls.email.dirty || passwordResetRequestForm.controls.email.touched || passwordResetCodeRequested) ? 'has-error':''">
                                <span class="material-icons">email</span>
                                <input type="text" placeholder="Please enter email address" class="form-control" formControlName="email" aria-autocomplete="list">
                                <ng-container *ngIf="passwordResetRequestForm.controls.email.errors && (passwordResetRequestForm.controls.email.dirty || passwordResetRequestForm.controls.email.touched || passwordResetCodeRequested)">
                                    <ng-container *ngIf="passwordResetRequestForm.controls.email.errors?.required">
                                        <div class="help-block text-danger mt-1">Please enter your email address</div>
                                    </ng-container>
                                    <ng-container *ngIf="passwordResetRequestForm.controls.email.errors?.email">
                                        <div class="help-block text-danger mt-1">Please enter a valid email</div>
                                    </ng-container>
                                </ng-container>
                            </div>
                            <div class="col-12 my-3">
                                <button type="submit" class="btn btn-orange s-btn">
                                    <i class="fa fa-spinner fa-spin mr-2" *ngIf="showLoader"></i>Send Password Reset Code
                                </button>
                            </div>                               
                        </div>
                    </form>
                </div>
            </div>
            <!-- End of form -->
        </div>
    </div>
</section>
<!-- End of Main conatiner -->
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { delay, retryWhen, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PartnerServicesService {

  private baseUrl = environment.apiUrl;

  private httpHeaders =  new HttpHeaders({
    'cache': 'true',
    'cache_group': 'partner_services'
  });

  constructor(private http: HttpClient) { }

  getAllPartnerServices(page: number) {
    return this.http.get(this.baseUrl + '/available_services?page=' + page, {headers: this.httpHeaders});
  }

  getPartnerServicesCategory() {
    return this.http.get(this.baseUrl + '/available_services/categories', {headers: this.httpHeaders});
  }

  getPartnerServicesByServiceCategoryName(service_category_name: string ,page: number) {
    if(service_category_name === 'All') {
        return this.http.get(this.baseUrl + '/available_services?page=' + page, {headers: this.httpHeaders});
    }
    return this.http.get(this.baseUrl + '/available_services/get_services/'+ service_category_name +'?page=' + page, {headers: this.httpHeaders});
  }

  getPartnerServiceDetails(partner_service_id: number) {
    return this.http.get(this.baseUrl + '/available_services/get_detail/' + partner_service_id, {headers: this.httpHeaders});
  }
}

import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DataShareService } from 'src/app/utils/data-share.service';
import { NewsService } from '../services/news.service';

@Component({
  selector: 'app-news-detail',
  templateUrl: './news-detail.component.html',
  styleUrls: ['./news-detail.component.css']
})
export class NewsDetailComponent implements OnInit, OnDestroy {

  news: any;
  latestNewsList: any[] = [];
  popularTags: any[] = [];
  private ngUnsubscribe: Subject<any> = new Subject();
  news_source_logo: string = '';
  news_image = '';

  constructor(private dataShareService: DataShareService, private newsService: NewsService,
    private router: Router) {
  }

  ngOnInit(): void {
    // this.getLatestNews();
    this.dataShareService.currentLoginStatus.pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(newsDetails => {
          var newsData = sessionStorage.getItem('news');
          this.news = newsData ? JSON.parse(newsData) : newsDetails;
          this.news_image = this.news.image;
          this.news_source_logo = this.news.source_logo;
          this.popularTags.push(this.news.category);
        });
  }

  // getLatestNews() {
  //   this.newsService.fetchLatestNwws(1).pipe(takeUntil(this.ngUnsubscribe))
  //     .subscribe((res: any) => {
  //       this.latestNewsList = res.data;
  //       this.popularTags = [...new Set(this.popularTags.concat([...new Set(this.latestNewsList.map(news => news.category))]))];
  //     }, (error: any) => {
  //       console.log("News content error", error);
  //     });
  // }

  detailNews(clickedNews: any) {
    this.news = clickedNews;
    this.news_image = this.news.image;
    this.news_source_logo = this.news.source_logo;
    sessionStorage.setItem('news', JSON.stringify(clickedNews));
  }

  /**
   * unsubcribing active subscription to avaoid memory leak
   */
   ngOnDestroy(): any {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

}

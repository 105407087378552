import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthenticationService } from './../login/services/authentication.service';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.css']
})
export class ForgetPasswordComponent implements OnInit, OnDestroy {

  private ngUnsubscribe: Subject<any> = new Subject();
  passwordResetRequestForm!: FormGroup;
  passwordResetCodeRequested: boolean = false;
  showLoader: boolean = false;

  constructor(private formBuilder: FormBuilder, private authenticationService: AuthenticationService,
    private toastr: ToastrService, private router: Router) { }

  ngOnInit(): void {
    this.passwordResetRequestForm = this.formBuilder.group({
        email: ['', [Validators.required, Validators.email]]
    });
  }

  requestPasswordResetCode() {
    this.passwordResetCodeRequested = true;
    if(this.passwordResetRequestForm.invalid) {
      return;
    }
    this.showLoader = true;
    const data = {
      email: this.passwordResetRequestForm.controls.email.value
    }
    this.authenticationService.requestPasswordResetCode(data)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.showLoader = false;
        this.passwordResetCodeRequested = false;
        this.toastr.success(res.success);
        const bae64EncodedEmail = btoa(this.passwordResetRequestForm.controls.email.value);
        setTimeout(()=> {
          this.passwordResetRequestForm.reset();
          this.router.navigate(['/reset-password/' + bae64EncodedEmail]);
          this.passwordResetRequestForm.reset();
        }, 2000)
      }, (err: any) => {
        this.showLoader = false;
        this.passwordResetCodeRequested = false;
        const errors = err.error.error;
        Object.keys(errors).forEach(key => {
          this.toastr.error(errors[key][0], key)
        });
      });
  }

  /**
   * unsubcribing active subscription to avaoid memory leak
   */
   ngOnDestroy(): any {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

}

<!-- Page header -->
<!-- <section class="page-header bg-news-detail">
    <div class="container">
        <div class="row">
            <div class="col-md-12 page-title text-center">
                <h2>News Detail</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
            </div>
        </div>
    </div>
</section> -->
<!-- End of Page header -->	

<!-- Main contents -->
<section class="content content-section pt-85">
    <div class="container">
        <div class="row">
            <div class="col-md-8">
                <div class="card-item detail">
                    <h3>{{news.title}}</h3>
                    <div class="top-card d-flex">
                        <div class="date-time">
                            <span><i class="far fa-calendar-alt"></i>{{news.published_date | date:'MMM d, y'}}</span>
                            <span class="ml-4"><i class="fas fa-clock"></i>{{news.published_date | dateAgo}}</span>
                        </div>
                        <div class="news-tag">
                            <a href="#">{{news.category}}</a>
                        </div>
                    </div>
                    <div class="my-3">
                        <img [src]="news.image || 'assets/img/default_banners/default-news.svg'"  class="img-fluid">
                    </div>
                    <div class="text-left mt-3">
                        
                        <p [innerHTML]="news.description" class="news-desc-from-api"></p>
                    </div>
                    <div class="news-source">
                        <div class="mt-2">
                            <img class="img-fluid" [src]="news.source_logo || 'assets/img/default_banners/default-news-logo.svg'">
                            <span class="ml-2">{{news.source | uppercase }}</span>
                        </div>
                        <a href="{{news.link}}" target="_blank" class="btn btn-orange">Read More</a>
                    </div>
                </div>					
            </div>
            <div class="col-md-4 right-card">
                <div class="recent-post">
                    <h4 class="d-block mb-4 text-center">Recent Post</h4>
                    <ng-container *ngIf="latestNewsList.length > 0; else noRecentNews">
                        <ng-container *ngFor="let news of latestNewsList; let i=index">
                            <ng-container *ngIf="i < 3">
                                <div class="post-list">
                                    <img (click)="detailNews(news)" [imagepreloader]="news.image" [targetSection]="'news'" class="img-fluid link">
                                    <div class="post-info ml-0 ml-md-3">
                                        <a (click)="detailNews(news)">{{news.title}}</a>
                                        <span>{{news.published_date | date:'MMM d, y'}}</span>
                                        <span class="ml-4"><i class="fas fa-clock"></i>{{news.published_date | dateAgo}}</span>
                                    </div>
                                </div>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                    <ng-template #noRecentNews>
                        <div class="row align-items-center justify-content-center">
                            <i class="fa fa-spinner fa-spin mr-2"></i>
                        </div>
                    </ng-template>
                </div>
                <div class="p-tags mt-4 mt-md-5">
                    <h4 class="d-block mb-4 text-center">Popular Tags</h4>
                    <div class="tag-lists">
                        <ng-container *ngIf="popularTags.length > 0; else noRecentNews">
                            <ng-container *ngFor="let tags of popularTags">
                                <a href="#">{{tags}}</a>
                            </ng-container>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End of Main contents -->
import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthenticationService } from '../login/services/authentication.service';

@Component({
  selector: 'app-verify-email',
  templateUrl: './verify-email.component.html',
  styleUrls: ['./verify-email.component.css']
})
export class VerifyEmailComponent implements OnInit, OnDestroy {

  private email: String = '';
  verifyEmailForm!: FormGroup;
  verifyEmailRequested: boolean = false;
  showLoader: boolean = false;
  showResendCodeLoader: boolean = false;
  private ngUnsubscribe: Subject<any> = new Subject();
  constructor(private activatedRoute: ActivatedRoute, private router: Router, private formBuilder: FormBuilder,
    private authenticationDataService: AuthenticationService, private toastr: ToastrService) {
    this.email = atob(this.activatedRoute.snapshot.paramMap.get('base64EncodedEmail') + '');
  }

  ngOnInit(): void {
    this.verifyEmailForm = this.formBuilder.group({
      code: ['', [Validators.required, Validators.minLength(1)]]
    });
  }

  verifyEmail() {
    this.verifyEmailRequested = true;
    if (this.verifyEmailForm.invalid) {
      return;
    }
    this.showLoader = true;
    let emailVerificationData = {
      email: this.email,
      code: this.verifyEmailForm.controls.code.value
    }
    this.authenticationDataService.requestEmailVerification(emailVerificationData)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.verifyEmailRequested = false;
        this.showLoader = false;
        this.toastr.success(res.success);
        this.router.navigate(['/login']);
        this.verifyEmailForm.reset();
      }, (err: any) => {
        this.showLoader = false;
        this.verifyEmailRequested = false;
        const errors = err.error.error;
        Object.keys(errors).forEach(key => {
          this.toastr.error(errors[key][0], key)
        });
      });
  }

  resentVerificationCode() {
    this.showResendCodeLoader = true;
    let resendCodeData = {
      email: this.email
    }
    this.authenticationDataService.requestEmailActivationcode(resendCodeData)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((res: any) => {
          this.showResendCodeLoader = false;
          this.toastr.clear();
          this.toastr.success(res.success, 'Success')
        }, (err: any) => {
          this.showResendCodeLoader = false;
          this.toastr.clear();
          const errors = err.error.error;
          Object.keys(errors).forEach(key => {
            this.toastr.error(errors[key][0], key)
          });
        });
  }

  /**
   * unsubcribing active subscription to avaoid memory leak
   */
    ngOnDestroy(): any {
      this.ngUnsubscribe.next();
      this.ngUnsubscribe.complete();
    }

}

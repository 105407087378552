import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NewsService } from '../news/services/news.service';
import { DataShareService } from '../utils/data-share.service';
import { AlertService } from './service/alert.service';

declare function alignTabs(): any;

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.css']
})
export class AlertComponent implements OnInit, OnDestroy {

  private ngUnsubscribe: Subject<any> = new Subject();
  isLoggedIn: boolean = false;
  activetab: string = 'latest-tab';
  /**
   * 
   */
  latestAlertList: any[] = [];
  latestAlertLimit: number = 0;
  showLatestAlertLoadMore: boolean = false;
  fetchingLatestAlerts: boolean = false;
  earthquakeList: any[] = [];
  earthquakeLimit: number = 0;
  showEarthquakeLoadMore: boolean = false;
  fetchingEarthquakes: boolean = false;
  volcanoList: any[] = [];
  volcanoLimit: number = 0;
  showVolcanoLoadMore: boolean = false;
  fetchingVolcanos: boolean = false;
  /**
   * variables declared for news list
   */
  latestNewsList: any[] = [];
  /**
   * variabled declared to send as a parameter to filter news
   */
   userGenrePreference: string[] = [];
   userLanguagePreference: string[] = [];
   userSourcePreference: string[] = [];
   /**
    * variables declared to store user location details
    */
   userLatitude: any = 0;
   usersLongitude: any = 0;
   locationAllowed: boolean = false;
   latestWarningList: any[] = [];
   fetchingWarningList: boolean = false;

  constructor(private alertService: AlertService, private toastr: ToastrService, private newsService: NewsService,
    private dataShareService : DataShareService) {
    if (localStorage.getItem('a_token') || sessionStorage.getItem('a_token')) {
      this.isLoggedIn = true;
    }
    if(localStorage.getItem('created_gps')) {
      const userLocation = localStorage.getItem('created_gps')?.split(':')[0];
      if(userLocation != undefined && userLocation.length > 0) {
        this.userLatitude = userLocation[0];
        this.usersLongitude = userLocation[1];
        this.locationAllowed = true;
        this.fetchWarningList();
      }
    } else {
      this.locationAllowed = false;
    }
  }

  ngOnInit(): void {
    /**
     * listen or check user login status
     */
     this.dataShareService.currentLoginStatus.pipe(takeUntil(this.ngUnsubscribe))
     .subscribe(status => {
       if (localStorage.getItem('a_token') != null || sessionStorage.getItem('a_token') != null) {
         this.isLoggedIn = true;
       } else {
         this.isLoggedIn = status;
       }
     });
    /**
     * subscribe to event for changing active tab
     */
    this.dataShareService.activeAlertTab.pipe(takeUntil(this.ngUnsubscribe))
    .subscribe((tab: any) => {
      if(tab) {
        this.activetab = tab;
      }
    });
    this.fetchLatestAlerts();
    this.fetchLatestEarthquakes();
    this.fetchLatestVolcanos();
    if(this.isLoggedIn) {
      this.fetchUserNewsPreference();
    } else {
      this.getLatestNews();
    }
    setTimeout(function(){
      alignTabs();
    },500);
  }

  /**
   * function to fetch user saved genre, language and source preference
   */
   fetchUserNewsPreference() {
    this.newsService.getUserNewsPreference().pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        if(res.length > 0) {
          this.userGenrePreference = res[0].genre;
          this.userLanguagePreference = res[0].language;
          this.userSourcePreference = res[0].source;
        }
        /**
         * fetch news with preferences
         */
        this.getLatestNews();
      });
  }

  /**
   * function to fetch latest alert list
   */
  fetchLatestAlerts() {
    this.fetchingLatestAlerts = true;
    this.alertService.getLatestAlerts(this.latestAlertLimit + 10)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.fetchingLatestAlerts = false;
        this.latestAlertList = this.latestAlertList.concat(res);
        /**
         * filter out dublicate objects
         */
        this.latestAlertList = this.latestAlertList.reduce((acc, x) =>
            acc.concat(acc.find((y: any) => y.code ? y.code === x.code : y.volcano_code === x.volcano_code) ? [] : [x])
          , []);
        this.latestAlertLimit = this.latestAlertLimit + 10;
        /**
         * if the latest alert length is 0 push -1 to the latestAlertList 
         * so that the proper information can be displayed to the users
         */
         if(this.latestAlertList.length <= 0) {
          this.latestAlertList = [-1];
        }
        /**
         * toogle load more button based on response length
         * latestAlertLimit is used because for every request limit=10 is sent
         */
        if(res.length < this.latestAlertLimit) {
          this.showLatestAlertLoadMore = false;
        } else {
          this.showLatestAlertLoadMore = true;
        }
      }, (err: any) => {
        this.fetchingLatestAlerts = false;
        // this.toastr.error('Failed to fetch latest alerts', 'Error');
      });
  }

  /**
   * function to fetch latest earthquake list
   */
  fetchLatestEarthquakes() {
    this.fetchingEarthquakes = true;
    this.alertService.getLatestEarthquakeList(this.earthquakeLimit + 10)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {

        this.fetchingEarthquakes = false;
        this.earthquakeList = this.earthquakeList.concat(res.slice(this.earthquakeLimit));
        // this.earthquakeList = this.earthquakeList.concat(res);
        /**
         * filter out dublicate objects
         */
        // this.earthquakeList = this.earthquakeList.reduce((acc, x) =>
        //     acc.concat(acc.find((y: any) => y.code === x.code) ? [] : [x])
        //   , []);
        this.earthquakeLimit = this.earthquakeLimit + 10;
        /**
         * if the earthquakeList length is 0 push -1 to the earthquakeList 
         * so that the proper information can be displayed to the users
         */
         if(this.earthquakeList.length <= 0) {
          this.earthquakeList = [-1];
        }
        /**
         * toogle load more button based on response length
         * earthquakeLimit is used because for every request limit=10 is sent
         */
        if(res.length < this.earthquakeLimit) {
          this.showEarthquakeLoadMore = false;
        } else {
          this.showEarthquakeLoadMore = true;
        }
      }, (err: any) => {
        this.fetchingEarthquakes = false;
        // this.toastr.error('Failed to fetch latest Earthquakes', 'Error');
      });
  }

  /**
   * function to fetch latest earthquake list
   */
   fetchLatestVolcanos() {
    this.fetchingVolcanos = true;
    this.alertService.getLatestVolcanoList(this.volcanoLimit + 10)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.fetchingVolcanos = false;
        this.volcanoList = this.volcanoList.concat(res.slice(this.volcanoLimit));
        this.volcanoLimit = this.volcanoLimit + 10;
        /**
         * if the volcanoList length is 0 push -1 to the volcanoList 
         * so that the proper information can be displayed to the users
         */
         if(this.volcanoList.length <= 0) {
          this.volcanoList = [-1];
        }
        /**
         * toogle load more button based on response length
         * volcanoLimit is used because for every request limit=10 is sent
         */
        if(res.length < this.volcanoLimit) {
          this.showVolcanoLoadMore = false;
        } else {
          this.showVolcanoLoadMore = true;
        }
      }, (err: any) => {
        this.fetchingVolcanos = false;
        // this.toastr.error('Failed to fetch latest Volcanos', 'Error');
      });
  }

  /**
   * function to fetch latest news
   */
   getLatestNews() {
    this.newsService.fetchLatestNwws(1, this.userGenrePreference, this.userSourcePreference, this.userLanguagePreference)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.latestNewsList = this.latestNewsList.concat(res.data);
        /**
         * if the latestNewsList length is 0 push -1 to the latestNewsList 
         * so that the proper information can be displayed to the users
         */
         if(this.latestNewsList.length <= 0) {
          this.latestNewsList = [-1];
        }
      }, (error: any) => {
        // this.toastr.error('Failed to fetch latest news', 'Error');
      });
  }

  /**
   * function to redirect user to external link of news
   * @param link news external link
   */
  openNewsUrl(link: string) {
    window.open(link, '_blank');
  }

  fetchWarningList() {
    this.fetchingWarningList = true;
    this.alertService.getWarningList(this.userLatitude, this.usersLongitude)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.fetchingWarningList = false;
        this.latestWarningList = res;
        /**
         * if the latestWarningList length is 0 push -1 to the latestWarningList 
         * so that the proper information can be displayed to the users
         */
        if(this.latestWarningList.length <= 0) {
          this.latestWarningList = [-1];
        }
      }, (err: any) => {
        this.fetchingWarningList = false;
        // this.toastr.error('Failed to fetch warning list', 'Error');
      });
  }

  changeActiveTab(tab: string) {
    this.activetab = tab;
  }

  trackAlertByCode(index: number, item: any) {
    if(item != -1 && item != '-1') {
      if(item.label == 'Earthquake') {
        return item.code;
      }
      return item.volcano_code;
    }
    return item;
  }

  trackNewsByLink(index: number, item: any) {
    if(item != -1 && item != '-1') {
      return item.link;
    }
    return item;
  }

  @HostListener('window:resize', ['$event'])
  sizeChange(event: any) {
    setTimeout(() => {
      alignTabs();
    }, 500);
  }

  /**
   * unsubcribing active subscription to avaoid memory leak
   */
   ngOnDestroy(): any {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

}

<!-- Page header -->
<!-- <section class="page-header bg-header-next">
    <div class="container">
        <div class="row">
            <div class="col-md-12 page-title text-center">
                <h2>{{japaneseMannerDetails?.title}}</h2>
            </div>
        </div>
    </div>
</section> -->
<!-- End of Page header -->

<!-- Main contents -->
<ng-container *ngIf="hasResponse; else fetchingDataFromApi">
    <section class="bg-breadcrumb">
		<div class="container">
			<nav aria-label="breadcrumb">
				<ol class="breadcrumb">
					<li class="breadcrumb-item"><a [routerLink]="['/japanese-manners']">Japanese Manners</a></li>
                    <li class="breadcrumb-item breadcrumb-link" (click)="redirectUsersToListingPage(japaneseMannerDetails.category)"><a class="link">{{japaneseMannerDetails.category.category_name}}</a></li>
					<li class="breadcrumb-item active" aria-current="page">{{japaneseMannerDetails.title}}</li>
				</ol>
			</nav>
		</div>
	</section>
    <section class="content detail-section content-with-breadcrumb-section">
    <!-- <section class="content content-section detail-section no-breadcrump-header"> -->
        <div class="container">
            <div class="row">
                <div class="col-md-8">
                    <ng-container *ngIf="japaneseMannerDetails != undefined">
                        <ng-container *ngIf="!japaneseMannerDetails.is_youtube; else youtubeJapaneseManner">
                            <img [src]="assetBaseUrl + japaneseMannerDetails.image || 'assets/img/default_banners/default-jp-manner.svg'" onerror="this.src='assets/img/default_banners/default-jp-manner.svg'" class="img-fluid details-img-section">
                        </ng-container>
                        <ng-template #youtubeJapaneseManner>
                            <iframe width="auto" height="170" [src]="japaneseMannerDetails.description | safeResourceUrl" title="{{japaneseMannerDetails.title}}" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </ng-template>
                        <h3>{{japaneseMannerDetails.title}}</h3>
                        <p *ngIf="!japaneseMannerDetails.is_youtube" [innerHTML]="japaneseMannerDetails.description"></p>
                    </ng-container>
                </div>
                <div class="col-md-4 right-card card-next">
                    <div class="recents-item">
                        <h4 class="d-block mb-2">{{category_name}}</h4>
                        <!-- <ng-container *ngIf="hasCategorywiseData; else fetchingCategorywiseData"> -->
                            <ng-container *ngIf="mannersByCategory.length > 0; else fetchingCategorywiseData">
                                <ng-container *ngFor="let manner of mannersByCategory; let i=index; trackBy:trackById;">
                                    <ng-container *ngIf="i < 3">
                                        <ng-container *ngIf="manner == -1; else hasCategorywiseJapaneseManners">
                                            <h6 class="col-lg-12 mt-3">
                                                Data unavailable at the moment.
                                            </h6>
                                        </ng-container>
                                        <ng-template #hasCategorywiseJapaneseManners>
                                            <div class="post-list d-flex">
                                                <ng-container *ngIf="!manner.is_youtube; else youtubeRecentJapaneseManner">
                                                    <img [imagepreloader]="assetBaseUrl + manner.image" [targetSection]="'jpmanners'" class="img-fluid">
                                                </ng-container>
                                                <ng-template #youtubeRecentJapaneseManner>
                                                    <iframe width="170" height="140" [src]="manner.description | safeResourceUrl" title="{{manner.title}}" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                                </ng-template>
                                                <div class="post-info ml-3">
                                                    <p class="link" (click)="japaneseDetailManners(manner)" [routerLink]="['/japanese-manners/'  + manner.category.category_name + '/' + manner.category.id + '/manner/' + manner.id]">{{manner.title}}</p>
                                                    <a class="btn-gray-outline link" (click)="redirectUsersToListingPage(manner.category)">{{manner.category.category_name}}</a>
                                                </div>
                                            </div>
                                        </ng-template>
                                    </ng-container>
                                </ng-container>
                            </ng-container>
                            <!-- <ng-template #emptyDataForCategory>
                                <h5>Data Unavailable.</h5>
                            </ng-template> -->
                        <!-- </ng-container> -->
                        <ng-template #fetchingCategorywiseData>
                            <section class="content content-section detail-section loading-skeleton avoid-skeleton-padding">
                                <div class="container">
                                                <div class="package-list d-flex">
                                                    <div class="s-default-img s-round mr-3" style="height: 70px; width: 80px"></div>
                                                    <div class="p-items">
                                                        <p class="mb-1">Package 1</p>
                                                        <strong class="amt d-block">¥ 1200</strong>
                                                    </div>
                                                </div>
                                                <div class="package-list d-flex">
                                                    <div class="s-default-img s-round mr-3" style="height: 70px; width: 80px"></div>
                                                    <div class="p-items">
                                                        <p class="mb-1">Package 1</p>
                                                        <strong class="amt d-block">¥ 1200</strong>
                                                    </div>
                                                </div>
                                </div>
                            </section>
                        </ng-template>
                        
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- End of Main contents -->
</ng-container>
<ng-template #fetchingDataFromApi>
    <!-- Main contents -->
    <section class="content content-section detail-section loading-skeleton no-breadcrump-header">
        <div class="container">
            <div class="row">
                <div class="col-md-9">
                    <div class="inner s-content mb-4" style="height: 400px;">
                    </div>
                    <h2 class="d-block mb-3">Lorem ipsum dolor sit amet, consectetu</h2>
                    <span>Praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio.</span>
                </div>
                <div class="col-md-3 right-card card-next">
                    <div class="mb-5">
                        <h4 class="d-block">Our Packages</h4>
                        <div class="package-list d-flex">
                            <div class="s-default-img s-round mr-3" style="height: 70px; width: 80px"></div>
                            <div class="p-items">
                                <p class="mb-1">Package 1</p>
                                <strong class="amt d-block">¥ 1200</strong>
                            </div>
                        </div>
                        <div class="package-list d-flex">
                            <div class="s-default-img s-round mr-3" style="height: 70px; width: 80px"></div>
                            <div class="p-items">
                                <p class="mb-1">Package 1</p>
                                <strong class="amt d-block">¥ 1200</strong>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- End of Main contents -->
</ng-template>
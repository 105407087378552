import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  private baseUrl = environment.apiUrl;
  private header = new HttpHeaders({accept: 'application/json'});

  constructor(private http: HttpClient) { }
 
  /**
   * function for normal registration
   * @param registrationData 
   * @returns 
   */
  requestRegistration(registrationData: any) {
    return this.http.post(this.baseUrl + '/register/', registrationData);
  }

  /**
   * function for normal login
   * @param authenticationData
   * @returns 
   */
  requestLogin(authenticationData: any) {
    return this.http.post(this.baseUrl + '/login/', authenticationData);
  }

  /**
   * function for gmail login
   * @param authenticationData 
   * @returns 
   */
  requestGoogleLogin(authenticationData: any) {
    return this.http.post(this.baseUrl + '/login/google/', authenticationData);
  }

  /**
   * function for gmail login
   * @param authenticationData 
   * @returns 
   */
   requestFacebookLogin(authenticationData: any) {
    return this.http.post(this.baseUrl + '/login/facebook/', authenticationData);
  }

  /**
   * function for email verification
   * @param emailVerificationData 
   * @returns 
   */
  requestEmailVerification(emailVerificationData: any) {
    return this.http.post(this.baseUrl + '/email/verify/', emailVerificationData);
  }

  /**
   * function for requesting email activation code
   * @param emailActivationData 
   * @returns 
   */
  requestEmailActivationcode(emailActivationData: any) {
    return this.http.post(this.baseUrl + '/email/activation/code/', emailActivationData);
  }

  /**
   * function for requesting password reset code
   * @param passwordResetCodeRequestData 
   * @returns 
   */
  requestPasswordResetCode(passwordResetCodeRequestData: any) {
    return this.http.post(this.baseUrl + '/email/password/reset/code/', passwordResetCodeRequestData);
  }

  /**
   * function for requesting password reset
   * @param passwordResetData 
   * @returns 
   */
  requestPasswordReset(passwordResetData: any) {
    return this.http.request('POST', this.baseUrl + '/email/password/reset/',  {body: passwordResetData});
  }


  requestNewAccessToken() {
    let data = {
      refresh: localStorage.getItem('r_token') || sessionStorage.getItem('r_token')
    }
    return this.http.post(this.baseUrl + '/token/refresh/', data, {headers: this.header});
  }
}

import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import { AboutComponent } from './about.component';

const  ABOUT: Routes = [
    { path: '', component : AboutComponent}
];
@NgModule({
    imports: [RouterModule.forChild(ABOUT)],
    exports: [RouterModule]
})
export class AboutRoutingModule {
}

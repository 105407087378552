import { Injectable } from '@angular/core';
import {
    HttpInterceptor,
    HttpHandler,
    HttpRequest,
    HttpResponse,
    HttpEvent
} from '@angular/common/http';
import { Observable, of, AsyncSubject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CacheService } from '../services/cache.service';

@Injectable()
export class CacheInterceptor implements HttpInterceptor {
    constructor(private cacheService: CacheService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        /**
         * check if the request is made for data manipulation
         * if the request if for create, update or delete or any kind of data manipulation
         * delete the cache by cache_group value
         */
        if (req.method === 'POST' || req.method === 'PUT' || req.method === 'DELETE' ||
            req.headers.get('cache') === 'delete') {
            this.cacheService.deleteCacheByGroup(req.headers.get('cache_group'));
            /**
             * check dependent cache
             */
            // if (req.headers.has('dependentCache')) {
            //     const dependentCache: string[] = req.headers.get('dependentCache').split(',');
            //     dependentCache.forEach(cacheGroup => this.cacheService.deleteCacheByGroup(cacheGroup));
            // }
        }
        /**
         * check if the cache persists or not
         * if cache exists for the requested api return response from cache
         */
        if (req.headers.get('cache_group') !== '' && req.headers.get('cache') === 'true' && req.method === 'GET') {
            const cachedResponse = this.cacheService.get(req);
            if (cachedResponse !== null) {
                // console.log('Response from cache => ' + cachedResponse);
                return of(cachedResponse);
            }
        }
        let authReq = req.clone({headers: req.headers.delete('cache')});
        authReq = authReq.clone({headers: authReq.headers.delete('dependentCache')});
        // console.log('List of headers at cache interceptor=> ' + authReq.headers.keys());
        return next.handle(authReq).pipe(
            tap(event => {
                if (event instanceof HttpResponse && event.body !== null && authReq.method === 'GET'
                    && req.headers.get('cache_group') !== '') {
                    // console.log('Event response => ' + event.body !== null);
                    this.cacheService.put(authReq, event);
                }
            })
        );
    }
}

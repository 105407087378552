import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'creditCardMask'
})
export class CreditCardMaskPipe implements PipeTransform {

    constructor(){}

  /**
   * Transform
   *
   * @param {string} plainCreditCard
   * @returns {string}
   */
  transform(plainCreditCard: string): string {
    let maskedCardNumber = plainCreditCard.replace(/.(?=.{4})/g, "*");
    let joy=maskedCardNumber.match(/.{1,4}/g);
    if(joy) {
        return joy.join(' ');
    }
    return '';
  }

}

<!-- Main conatiner -->
<section class="login-section">
    <div class="container">
        <div class="row">
            <div class="col-md-8 col-lg-4 mx-auto">			
                <div class="form-inner verify">
                    <img src="assets/img/icon-email-verify.svg" class="img-fluid mb-4">
                    <h2>Verify your email address</h2>
                    <!-- form -->
                    <form class="form-pannel" [formGroup]="verifyEmailForm" (ngSubmit)="verifyEmail()">
                        <div class="form-row">
                            <div class="col-md-12">
                                <p>Please enter code sent to your email address.</p>
                            </div>
                            <div class="form-group col-md-12" [ngClass]="verifyEmailForm.controls.code.errors && (verifyEmailForm.controls.code.dirty || verifyEmailForm.controls.code.touched || verifyEmailRequested) ? 'has-error':''">
                                <span class="fas fa-asterisk"></span>
                                <input type="text" placeholder="Please enter code" class="form-control" formControlName="code" aria-autocomplete="list">
                                <ng-container *ngIf="verifyEmailForm.controls.code.errors && (verifyEmailForm.controls.code.dirty || verifyEmailForm.controls.code.touched || verifyEmailRequested)">
                                    <ng-container *ngIf="verifyEmailForm.controls.code.errors?.required">
                                        <div class="help-block text-danger mt-1">Code is required</div>
                                    </ng-container>
                                    <ng-container *ngIf="verifyEmailForm.controls.code.errors?.minlength">
                                        <div class="help-block text-danger mt-1">At least 1 character required</div>
                                    </ng-container>
                                </ng-container>
                            </div>
                            <div class="col-12 my-3">
                                <button type="submit" class="btn btn-orange s-btn m-2">
                                    <i class="fa fa-spinner fa-spin mr-2" *ngIf="showLoader"></i>Verify Email
                                </button> &nbsp;&nbsp;
                                <button type="button" class="btn btn-orange s-btn" (click)="resentVerificationCode()">
                                    <i class="fa fa-spinner fa-spin mr-2" *ngIf="showResendCodeLoader"></i>Resend Code
                                </button>
                                <!-- <input type="submit" class="btn s-btn" value="Verify Email"> -->
                                <!-- <input type="button" class="btn s-btn" value="Resend Code" (click)="resentVerificationCode()"> -->
                            </div>                               
                        </div>
                    </form>
                </div>
            </div>
            <!-- End of form -->
        </div>
    </div>
</section>
<!-- End of Main conatiner -->
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DataShareService } from 'src/app/utils/data-share.service';
import { environment } from 'src/environments/environment';
import { JapaneseMannerService } from '../service/japanese-manner.service';

@Component({
  selector: 'app-japanese-manner-details',
  templateUrl: './japanese-manner-details.component.html',
  styleUrls: ['./japanese-manner-details.component.css']
})
export class JapaneseMannerDetailsComponent implements OnInit, OnDestroy {

  private ngUnsubscribe: Subject<any> = new Subject();
  assetBaseUrl = environment.assetBaseUrl;
  hasResponse : boolean = false;
  // hasCategorywiseData: boolean = false;
  category_name: string = '';
  category_id: number = 0;
  manner_id: number = 0;
  japaneseMannerDetails: any;
  // recentJapaneseManners: any[] = [];
  mannersByCategory: any[] = [];

  constructor(private service: JapaneseMannerService, private activatedRoute: ActivatedRoute,
              private toastr: ToastrService, private dataShareService: DataShareService,
              private router: Router) {
    this.category_name = this.activatedRoute.snapshot.paramMap.get('categoryname') || '';
    this.category_id = Number(this.activatedRoute.snapshot.paramMap.get('jpcategoryid')) || 0;
    this.manner_id = Number(this.activatedRoute.snapshot.paramMap.get('jpmannerid')) || 0;
   }

  ngOnInit(): void {
    this.getJapaneseMannerDetails();
    this.getJapaneseMannersByCategory();
    // this.getAllJapaneseManners();
  }

  // getAllJapaneseManners() {
  //   this.service.getAllJapaneseManners(1).pipe(takeUntil(this.ngUnsubscribe))
  //     .subscribe((res: any) => {
  //       this.recentJapaneseManners = res.jp_manners;
  //     }, (err: any) => {
  //       this.toastr.error('Unable to fetch latest japanese manners', 'ERROR');
  //     });
  // }

  getJapaneseMannerDetails() {
    this.service.getJapaneseMannerDetails(this.manner_id).pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.japaneseMannerDetails = res.jp_manner;
        this.hasResponse = true;
      }, (err: any) => {
        this.hasResponse = false;
        // this.toastr.error('Unable to fetch japanese manner details', 'ERROR');
      });
  }

  japaneseDetailManners(japaneseManner: any) {
    this.japaneseMannerDetails = japaneseManner;
  }

  getJapaneseMannersByCategory() {
    this.service.getJapaneseMannersByCategory(this.category_id, 1).pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.mannersByCategory = res.jp_manners;
        if(this.mannersByCategory.length <= 0) {
          this.mannersByCategory = [-1];
        }
        // this.hasCategorywiseData = true;
      }, (err: any) => {
        // this.hasCategorywiseData = false;
        // this.toastr.error('Failed to fetch Japanese Manner by Categories. Please try again.', 'ERROR');
      });
  }

  /**
   * function to redirect user to japanese manners listing page with the clicked category as active category
   */
  redirectUsersToListingPage(category: any) {
    this.router.navigate(['/japanese-manners'])
    .then(() => {
      this.dataShareService.changeJapaneseMannerActiveCategory(category);
    });
  }

  trackById(index: number, item: any): any {
    if(item != -1 && item != '-1') {
      return item.id;
    }
    return item;
  }

  /**
   * unsubcribing active subscription to avaoid memory leak
   */
   ngOnDestroy(): any {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

}
